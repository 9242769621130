<template>
  <div class="gf-bv">
    <div class="stair-wrapper">
      <div class="container">
        <div class="row">
          <div
            v-if="showFieldSearch"
            class="pfv-col-12 my-2 text-center"
          >
            <button
              class="pfv-btn pfv-btn-danger pfv-btn-search"
              @click="onSearchClick()"
            >
              <i class="fas fa-search" />
              &nbsp;{{ fieldSearchText }}
            </button>
          </div>
        </div>
        <div class="scroller-container">
          <div class="scroller text-center">
            <a
              ref="down"
              :href="`#stair-${currentStair}`"
              class="text-white"
              @click="moveDown()"
            ><i
              class="fa fa-2x fa-arrow-down"
              style="color: white"
              aria-hidden="true"
            /></a>
            <a
              ref="up"
              :href="`#stair-${currentStair}`"
              class="text-white"
              :class="{ 'up-disabled': topMost }"
              @click="moveUp()"
            ><i
              class="fa fa-2x fa-arrow-up"
              style="color: white"
              aria-hidden="true"
            /></a>
          </div>
        </div>
        <div class="stair-container">
          <Blocks
            v-for="(block, i) in sets"
            :id="`stair-${i + 1}`"
            :key="i"
            :view-id="currentStair"
            :set-number="i"
            :block-data="block.data"
            @itemClicked="onItemClick"
          />
        </div>
      </div>
    </div>
    <lightbox-search
      ref="lightbox-search-ref"
      :show-searchbox="showSearchbox"
      :item-images="itemImages"
      :additional-query-params="additionalQueryParams"
      @close="searchBoxClosed"
      @on-click-item="onItemClick"
    />
    <lightbox-item
      v-if="showLightbox"
      ref="lightbox-item-ref"
      :header-hero="selectedItemTitle"
      :item="lightboxData"
      :social-sharing-setup="socialSharingSetup"
      :facebook-link="facebookLikeUrl"
      :donate-link="donateLink"
      :donate-button-label="donateButtonLabel"
      :donor-label="donorLabel"
      @closed="lightboxClosed"
    />
  </div>
</template>

<script>
import Blocks from "./Blocks";
import { mutations, store } from "../../common/store";
import ComponentBase from "../inc/ComponentBase";
import LightboxSearch from "../lightboxSearch";
import LightboxItem from "../lightboxItem";
import apiService from '../inc/apiService'

export default {
    components: {
        Blocks,
        LightboxSearch,
        LightboxItem,
    },

    mixins: [ComponentBase],

    data() {
        return {
            facebookLikeUrl: "",
            blockItems: 0,
            topMost: true,
            stairStatus: "",
            stairItems: 1,
            currentStair: 1,
            sets: [],
            showSearchbox: false,
            showLightbox: false,
            lightboxData: {},
        };
    },

    computed: {
        pageSize() {
            return (
                this.additionalSettings.items *
                this.additionalSettings.setsPerPage
            );
        },
    },

    watch: {
        currentStair() {
            if (this.currentStair === 1) {
                this.topMost = true;
            } else {
                this.topMost = false;
            }
        },
    },

    created() {
        if (this.colours.filter((x) => x.name == "blockColour").length > 0)
            mutations.setBlockColor(
                this.colours.filter((x) => x.name == "blockColour")[0].value
            );

        this.blockItems = this.additionalSettings.items;
        mutations.setBlockItems(this.blockItems);
        mutations.setBlockSets(this.additionalSettings.setsPerPage);
    },

    async mounted() {
        this.$_ComponentBase_loadAdditionalQueryParams();
        this.sets = [];
        await this.loadSet(this.currentStair);
        await this.loadSet(this.currentStair + 1);
    },

    methods: {
        async loadSet(index) {
            var data = await this.getDigestData(index);
            this.sets.push({ index: index, data: data });
        },

        async getDigestData(index) {
            var data = await apiService.searchPaginatedItems(
                this.pageSize,
                index,
                this.additionalQueryParams
            );
            return data.Pages;
        },

        async moveDown() {
            this.currentStair++;
            await this.loadSet(this.currentStair + 1);

            this.stairItems++;
        },

        setSocialShareParameters(selectedItem) {
            this.socialSharingSetup.url =
                window.location.href.split("?")[0] +
                "?" +
                this.queryParamName +
                "=" +
                selectedItem.Id;
            this.socialSharingSetup.quote = this.performVariableSubstitutions(
                this.socialSharingSetup.quote,
                selectedItem
            );
            this.selectedItem = selectedItem;
            this.facebookLikeUrl =
                this.facebookLink +
                "&href=" +
                encodeURIComponent(this.socialSharingSetup.url);
        },

        performVariableSubstitutions(originalString, pageProperties) {
            var newString = originalString.replace(
                "#PAGETITLE#",
                pageProperties.PageTitle
            );
            newString.replace("#CREATORNAME#", pageProperties.CreatorName);
            newString.replace("#RAISED#", pageProperties.Total);
            newString.replace("#RAISETARGET#", pageProperties.RaiseTarget);
            return newString;
        },

        moveUp() {
            if (this.currentStair === 1) {
                return;
            }
            this.currentStair--;
            this.stairItems--;
        },

        onSearchClick() {
            this.showSearchbox = true;
        },

        onItemClick(val) {
            this.setSocialShareParameters(val);
            this.lightboxData = val;
            if (this.itemImages && this.itemImages[0])
                this.lightboxData.baseImage = this.itemImages[0].imageUrl;
            this.showLightbox = true;
        },

        searchBoxClosed() {
            this.showSearchbox = false;
        },
        lightboxClosed() {
            this.showLightbox = false;
        },
    },
};
</script>

<style lang="scss">
@import "../../scss/stair-app.scss";

.gf-bv {
    background: #6495ed;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.stair-container {
    scroll-behavior: smooth;
    height: 570px;
    overflow: hidden;
}
.scroller {
    z-index: 9;
    a {
        margin-right: 15px;
    }
}
</style>
