var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item-row row mx-0"},[_c('popper',{attrs:{"visible-arrow":false,"options":{
      placement: 'bottom',
      modifiers: {
        preventOverflow: {
          boundariesElement: '.stair-container'
        }
      }
    }}},[(_vm.userData.data)?_c('div',{staticClass:"pfv-hover-text py-1 px-2",class:_vm.perpectiveClass},[_vm._v("\n      "+_vm._s(_vm.userData.data.PageTitle)+"\n    ")]):_c('div'),_c('div',{staticClass:"item-col",attrs:{"slot":"reference"},slot:"reference"},[_c('div',{staticClass:"perspective"},[_c('div',{class:_vm.stepClass,style:({ 'background-color': _vm.setBackground() }),attrs:{"title":""},on:{"click":_vm.itemClicked}},[(_vm.isExtended && _vm.showOverlayImage)?_c('img',{staticClass:"fighter",attrs:{"src":_vm.fighterStyle}}):_vm._e()])]),_vm._v("\n      ​\n    ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }