<template>
  <div id="app">
    <component
      :is="componentToLoad"
      v-if="ready"
      v-bind="$props"
    >
      <template
        v-for="(index, name) in $scopedSlots"
        v-slot:[name]="data"
      >
        <slot
          :name="name"
          v-bind="data"
        />
      </template>
    </component>
  </div>
</template>

<script>
import Vue from "vue";
import ComponentBase from "./components/inc/ComponentBase";
import Modes from "./common/modes";
import BootstrapVue from "bootstrap-vue";
import customBootstrapStyles from "./scss/custom.scss";
import { mutations, store } from "./common/store"

Vue.use(BootstrapVue);

export default {
    name: "GfPagesFieldView",

    mixins: [ComponentBase],

    data() {
      return {
        ready: false
      }
    },

    computed: {
        componentToLoad() {
            return Modes[this.mode.type].baseComponent;
        }
    },

    mounted() {
        this.setupServiceData();
        this.ready = true; 
    },

    methods: {
        setupServiceData: function() {
            mutations.setApiDomain(this.apiDomain)
            mutations.setEventCampaignId(this.eventCampaignId)
            mutations.setExcludeDisabledFundraisingPages(this.excludeDisabledFundraisingPages)
        }
    }
};
</script>

<style>
@import "https://use.fontawesome.com/releases/v5.5.0/css/all.css";

html,
body {
    margin: 0;
    padding: 0;
}

#app {
    display: inline-block;
    position: relative;
    height: 100vh;
    max-height: 500px;
    width: 100%;
}
</style>
