<template>
  <field
    id="pfv-the-field"
    class="pfv-the-field"
    :style="{ backgroundImage: 'url(' + backgroundImage + ')' }"
  >
    <!-- paginations -->
    <div
      id="pfv-tool-bar"
      class="pfv-tool-bar"
    >
      <div class="pfv-row">
        <div
          class="pfv-col-12 text-center"
          v-html="htmlHeader"
        />
      </div>
      <div class="pfv-row">
        <div class="pfv-col-2">
          <div
            class="pfv-float-left pfv-toolbar-page-button"
            :class="prevPageButtonStyle"
            @click="onPrevPageClick()"
          >
            <i class="fas fa-arrow-left" />
          </div>
        </div>
        <div
          v-if="showFieldSearch"
          class="pfv-col-8 text-center"
        >
          <button
            class="pfv-btn pfv-btn-danger pfv-btn-search"
            @click="onSearchClick()"
          >
            <i class="fas fa-search" />
            &nbsp;{{ fieldSearchText }}
          </button>
          <span v-html="htmlButton" />
        </div>
        <div class="pfv-col-2">
          <div
            class="pfv-float-right pfv-toolbar-page-button"
            :class="nextPageButtonStyle"
            @click="onNextPageClick()"
          >
            <i class="fas fa-arrow-right" />
          </div>
        </div>
      </div>
    </div>

    <lightbox-search
      ref="lightbox-search-ref"
      :show-searchbox="showSearchbox"
      :item="selectedItem"
      :item-images="itemImages"
      :additional-query-params="additionalQueryParams"
      @close="showSearchbox = false"
      @on-click-item="onItemClick"
    />

    <lightbox-item
      v-if="showLightbox"
      ref="lightbox-item-ref"
      :header-hero="selectedItemTitle"
      :item="selectedItem"
      :social-sharing-setup="socialSharingSetup"
      :donor-label="donorLabel"
      :show-charity-name="showCharityName"
      :charity-label="charityLabel"
      :donate-link="donateLink"
      :facebook-link="facebookLikeUrl"
      :donate-button-label="donateButtonLabel"
    />

    <field-view-item
      v-for="(item, index) of items"
      :id="index"
      :key="index"
      :item="item"
      :item-top-offset-scaling="itemTopOffsetScaling"
      :the-canvas-div="theCanvasDiv"
      :show-item-hover-text="showItemHoverText"
      @on-click-item="onItemClick"
    />

    <fisher-yates
      ref="fisher-yates"
      :rows="fieldRows"
      :columns="fieldColumns"
    />
  </field>
</template>

<script>
import Vue from "vue";
import ComponentBase from "../inc/ComponentBase.vue";
import axios from "axios";
import modes from "../../common/modes";

import FisherYates from "../inc/fisher-yates.vue";
import LightboxItem from "../lightboxItem.vue";
import LightboxSearch from "../lightboxSearch.vue";
import vueDebounce from "vue-debounce";
import Field from "./Field.vue";
import FieldViewItem from "./FieldViewItem.vue";
import apiService from '../inc/apiService'

var maxItemImages;
Vue.use(vueDebounce);

export default {
    components: {
        Field,
        FieldViewItem,
        LightboxItem,
        LightboxSearch,
        FisherYates
    },
    
    mixins: [ComponentBase],

    data() {
        return {
            fisherYates: null,
            facebookLikeUrl: "",
            showHover: false,
            showLightbox: false,
            showSearchbox: false,
            selectedItem: null,
            hoverText: "Not Set",
            hoverTop: 400,
            hoverLeft: 400,
            info: null,
            loading: true,
            errored: false,
            items: [],
            pageDetails: null,
            currentPageIndex: 1,
            totalPages: 1,
            fieldRows: 0,
            fieldColumns: 0,
            theCanvasDiv: {
                width: 900,
                height: 500
            },
            searchingItem: false,
            itemClickable: true
        };
    },

    computed: {
        prevPageButtonStyle() {
            if (this.$data.currentPageIndex <= 1) return "pfv-disabled";
            else return "";
        },
        nextPageButtonStyle() {
            if (this.$data.currentPageIndex >= this.$data.totalPages)
                return "pfv-disabled";
            else return "";
        }
    },

    created: async function() {
        window.addEventListener("resize", this.handleResize);       
    },

    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },

    mounted: async function() {
        this.$_ComponentBase_loadAdditionalQueryParams();
        this.facebookLikeUrl = this.facebookLink;
        this.searchingItem = this.$_ComponentBase_isAskedToSearchItem();
        maxItemImages = this.itemImages.length;
        this.fisherYates = this.$refs["fisher-yates"];
        this.loadPages();
    },

    methods: {
        async loadPages() {
            var vm = this;
            var $donors = this.$data.donors;

            this.setFieldItemDensity();

            await vm.loadItems();

            if (vm.searchingItem) {
                vm.performSearch();
            }
        },

        async loadItems() {
            var vm = this;
            var pageSize = this.fieldRows * this.fieldColumns;
            var loader = modes[vm.mode.type].loader;
            var eventFrpLoader = require(`../inc/fieldItemLoaders/${loader}`);

            var items = await eventFrpLoader.default.load(vm);

            if (items !== null) {
                vm.pageDetails = items;
                vm.items = vm.setFieldItems(items.Pages);
                vm.totalPages = items.TotalPages;
            }
        },

        async performSearch() {
            var vm = this;
            await apiService
                .searchItem(this.$data.searchedItemId, vm.mode.type)
                .then((pageDetails) => {
                    if (pageDetails !== null) {
                        vm.$data.pageDetails.Pages.concat(pageDetails.Pages);

                        var itemFound = vm.setFieldItems(pageDetails.Pages);

                        const index = vm.$data.items.findIndex(i => i.Id == this.$data.searchedItemId)
                        itemFound[0].position.distance = 30;
                        itemFound[0].position.horizion = 40;
                        itemFound[0].zindex = 500;

                        if (index > -1)
                          vm.$data.items[index] = itemFound[0];

                        vm.searchingItem = false;
                        vm.onItemClick(itemFound[0]);
                    }
                });
        },

        handleResize() {
            const oldDeviceColumns = this.$data.fieldColumns;

            this.setFieldItemDensity();

            //Device size changed , require a data reload
            if (this.$data.fieldColumns !== oldDeviceColumns) {
                this.loadPages();
            }

            if (this.$data.pageDetails !== null) {
                this.$data.items = this.setFieldItems(
                    this.$data.pageDetails.Pages
                );
            }

            this.$forceUpdate();
            return true;
        },

        onPrevPageClick() {
            if (this.$data.currentPageIndex > 1) {
                this.$data.currentPageIndex--;
                this.loadPages();
            }
        },

        onNextPageClick() {
            if (this.$data.currentPageIndex < this.$data.totalPages) {
                this.$data.currentPageIndex++;
                this.loadPages();
            }
        },

        setFieldItemDensity() {
            this.getClientRect();
            this.$data.fieldColumns = Math.floor(
                this.$data.theCanvasDiv.width /
                    this.itemsToScreenWidthDistributionScaling
            );
            this.$data.fieldRows = 4;
        },

        onSearchClick() {
            this.selectedItem = this.$data.items[0];
            this.$data.showSearchbox = true;

            const lightboxSearch = this.$refs["lightbox-search-ref"];
            lightboxSearch.showSearchPopup();
        },

        setSocialShareParameters(selectedItem) {
            if(this.additionalSettings && this.additionalSettings.shareMode && this.additionalSettings.shareMode === 'pageUrl'){
                this.socialSharingSetup.url = selectedItem.Url;
            }
            else{
                this.socialSharingSetup.url =
                    window.location.href.split("?")[0] +
                    "?" +
                    this.queryParamName +
                    "=" +
                    selectedItem.Id;
            }
            this.socialSharingSetup.quote = this.performVariableSubstitutions(
                this.socialSharingSetup.quote,
                selectedItem
            );
            this.selectedItem = selectedItem;
            this.facebookLikeUrl =
                this.facebookLink +
                "&href=" +
                encodeURIComponent(this.socialSharingSetup.url);
        },

        onItemClick(selectedItem) {
            if (!this.itemClickable) return;
            this.setSocialShareParameters(selectedItem);
            this.$data.showLightbox = true;
            const lightboxItem = this.$refs["lightbox-item-ref"];
            if (lightboxItem) {
                lightboxItem.showItemPopup();
            }
        },

        performVariableSubstitutions(originalString, pageProperties) {
            var newString = originalString.replace(
                "#PAGETITLE#",
                pageProperties.PageTitle
            );
            newString.replace("#CREATORNAME#", pageProperties.CreatorName);
            newString.replace("#RAISED#", pageProperties.Total);
            newString.replace("#RAISETARGET#", pageProperties.RaiseTarget);
            return newString;
        },

        setFieldItemStyle(item, index) {
            /************* select random item image and hover image from supplied options */
            const itemImageIndex = this.fisherYates.getItemPosition(
                0,
                maxItemImages
            );
            
            if (this.itemImages === [] || this.itemImages.length === 0) {
                item.baseImage = item.ImagePath;
                item.hoverImage = item.ImagePath;
            } else {
                item.baseImage = this.itemImages[itemImageIndex].imageUrl;
                item.hoverImage = this.itemImages[itemImageIndex].hoverImageUrl;
            }
            const position = this.fisherYates.getNextPosition();
            if (position) {
                item.position = position;
            } else {
                console.log("Error in perspective calculation!");
            }
        },

        setFieldItems(pageDetails) {
            this.fisherYates.shuffleSlots();
            pageDetails.forEach(this.setFieldItemStyle);
            return pageDetails;
        },

        getClientRect() {
            const theFieldDiv = document.getElementById("pfv-the-field");
            if (theFieldDiv) {
                const clientRects = theFieldDiv.getClientRects();
                this.$data.theCanvasDiv = clientRects[0];
            }
        }
    }
};
</script>

<style>
@import "https://use.fontawesome.com/releases/v5.5.0/css/all.css";
html,
body {
    margin: 0;
    padding: 0;
}

#app {
    display: inline-block;
    position: relative;
    height: 100vh;
    max-height: 500px;
    width: 100%;
}
.pfv-the-field {
    background-size: 100% 100%;
}

.pfv-tool-bar {
    position: absolute;
    z-index: 998;
    top: 10%;
    left: 0;
    width: 100%;
    height: 5%;
    display: table;
}
.pfv-toolbar-page-button {
    cursor: pointer;
    margin: 10px;
}
.pfv-disabled {
    display: none;
}
</style>
