var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('popper',{attrs:{"visible-arrow":false,"options":{
      placement: 'top',
      modifiers: {
        preventOverflow: {
          boundariesElement: _vm.container, 
        },
        offset: { offset: '0,10px' } 
      }
    }}},[(_vm.showItemHoverText)?_c('div',{staticClass:"pfv-hover-text py-1 px-2"},[_vm._v("\n      "+_vm._s(_vm.item.PageTitle)+"\n    ")]):_vm._e(),_c('div',{class:_vm.itemCSSClass,staticStyle:{"position":"absolute"},style:({zIndex: _vm.item.position.zindex, transform: _vm.perspectiveScale, top: _vm.perspectiveTop, left:_vm.perspectiveLeft }),attrs:{"slot":"reference","v-if":_vm.theCanvasDiv},on:{"mouseover":function($event){_vm.hovering = true},"mouseleave":function($event){_vm.hovering = false},"click":_vm.onItemClick},slot:"reference"},[_c('img',{ref:"image",style:({zIndex: _vm.item.position.zindex}),attrs:{"src":encodeURI(_vm.imagePath)}})])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }