<script>
export default {
    props: {
        apiDomain: {
            type: String,
            default: "https://api.gofundraise.com",
        },
        eventCampaignId: {
            type: String,
            default: "",
        },

        fundraisingPageId: {
            type: Number,
            default: 0,
        },

        tags: {
            type: Array,
            default: () => [],
        },

        pageType: {
            type: String,
            default: null,
        },

        tagIds: {
            type: Array,
            default: () => [],
        },

        mode: {
            type: Object,
            default: () => {
                return {
                    type: "STD",
                };
            },
        },

        backgroundImage: {
            type: String,
            default:
                "https://cdn.gofundraise.com.au/Upload/176182/images/bg.jpg",
        },

        queryParamName: {
            type: String,
            default: "poppyid",
        },

        /**
         * Shows or hides the search button. When set to false, the search button will not be available on the field view.
         */
        showFieldSearch: {
            type: Boolean,
            default: true,
        },

        /**
         * Shows or hides the charity name in the lightbox.
         */
        showCharityName: {
            type: Boolean,
            default: false,
        },

        /**
         * Shows or hides the hover text above each field item.
         */
        showItemHoverText: {
            type: Boolean,
            default: true,
        },

        fieldSearchText: {
            type: String,
            default: "Search for poppies",
        },

        donorLabel: {
            type: String,
            default: "POPPY DONATED BY",
        },

        charityLabel: {
            type: String,
            default: "Supporting",
        },

        selectedItemTitle: {
            type: String,
            default: "IN LOVING MEMORY",
        },

        donateLink: {
            type: String,
            default: "https://www.gofundraise.com.au/pages/create",
        },

        facebookLink: {
            type: String,
            default:
                "https://www.facebook.com/plugins/like.php?width=450&layout=standard&action=like&size=small&show_faces=true&share=false&height=80&appId=2656598217695982",
        },

        itemTopOffsetScaling: {
            type: Number,
            default: 0,
        },

        itemsToScreenWidthDistributionScaling: {
            type: Number,
            default: 100,
        },

        socialSharingSetup: {
            type: Object,
            default: function () {
                return {
                    url: "http://www.gofundraise.com.au/",
                    title: "Poppy field",
                    description: "Poppy field details",
                    quote: "gofundraise",
                    hashtags: "gofundraise",
                    twitterUser: "gofundraise",
                };
            },
        },

        colours: {
            type: Array,
            default: () => [],
        },

        itemImages: {
            type: Array,
            default: () => [
                {
                    imageUrl:
                        "https://cdn.gofundraise.com.au/Upload/176182/images/poppy1.png",
                    hoverImageUrl:
                        "https://cdn.gofundraise.com.au/Upload/176182/images/poppy1_hover.png",
                },
                {
                    imageUrl:
                        "https://cdn.gofundraise.com.au/Upload/176182/images/poppy2.png",
                    hoverImageUrl:
                        "https://cdn.gofundraise.com.au/Upload/176182/images/poppy2_hover.png",
                },
                {
                    imageUrl:
                        "https://cdn.gofundraise.com.au/Upload/176182/images/poppy3.png",
                    hoverImageUrl:
                        "https://cdn.gofundraise.com.au/Upload/176182/images/poppy3_hover.png",
                },
                {
                    imageUrl:
                        "https://cdn.gofundraise.com.au/Upload/176182/images/poppy4.png",
                    hoverImageUrl:
                        "https://cdn.gofundraise.com.au/Upload/176182/images/poppy4_hover.png",
                },
                {
                    imageUrl:
                        "https://cdn.gofundraise.com.au/Upload/176182/images/poppy5.png",
                    hoverImageUrl:
                        "https://cdn.gofundraise.com.au/Upload/176182/images/poppy5_hover.png",
                },
                {
                    imageUrl:
                        "https://cdn.gofundraise.com.au/Upload/176182/images/poppy6.png",
                    hoverImageUrl:
                        "https://cdn.gofundraise.com.au/Upload/176182/images/poppy6_hover.png",
                },
                {
                    imageUrl:
                        "https://cdn.gofundraise.com.au/Upload/176182/images/poppy7.png",
                    hoverImageUrl:
                        "https://cdn.gofundraise.com.au/Upload/176182/images/poppy7_hover.png",
                },
                {
                    imageUrl:
                        "https://cdn.gofundraise.com.au/Upload/176182/images/poppy8.png",
                    hoverImageUrl:
                        "https://cdn.gofundraise.com.au/Upload/176182/images/poppy8_hover.png",
                },
            ],
        },

        additionalSettings: {
            type: Object,
            default: () => {},
        },

        donateButtonLabel: {
            type: String,
            default: "DONATE",
        },

        htmlHeader: {
            type: String,
            default: "",
        },

        htmlButton: {
            type: String,
            default: "",
        },

        initialQueryId: {
            type: Number,
            default: 0,
        },
        excludeDisabledFundraisingPages: {
          type: Boolean,
          default: false,
       }
    },

    data() {
        return {
            searchedItemId: 0,
            additionalQueryParams: {},
        };
    },

    methods: {
        $_ComponentBase_loadAdditionalQueryParams() {
            if (this.tags && this.tags.length > 0)
              this.additionalQueryParams.tags = this.tags.join(",");

            if (this.tagIds && this.tagIds.length > 0)
                this.additionalQueryParams.tagIds = this.tagIds.join(",");

            if (this.pageType && this.pageType.length > 0)
                this.additionalQueryParams.pagetype = this.pageType;

            if (this.fundraisingPageId && this.fundraisingPageId > 0) {
                this.additionalQueryParams.pageid = this.fundraisingPageId;
                this.additionalQueryParams.includeteammembers = true;
            }

        },

        $_ComponentBase_isAskedToSearchItem() {
            var queryString = window.location.search.slice(1);
            if (queryString) {
                queryString = queryString.split("#")[0];
                var arr = queryString.split("&");

                for (var i = 0; i < arr.length; i++) {
                    var a = arr[i].split("=");

                    var paramName = a[0];
                    var paramValue = typeof a[1] === "undefined" ? true : a[1];

                    paramName = paramName.toLowerCase();
                    if (paramName == this.queryParamName) {
                        this.searchedItemId = paramValue;
                        return true;
                    }
                }
            }

            if(this.initialQueryId > 0){
                this.searchedItemId = this.initialQueryId;
                return true;
            }

            return false;
        },
    },
};
</script>
