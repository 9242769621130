<template>
  <div>
    <div
      v-for="(set, x) in blockItems"
      :key="x"
      :class="((x % 2) === 0) ? stairEvenClass : stairOddClass"
      class="staircase"
    >
      <BlockItem
        v-for="(item, index) in set"
        :key="index"
        :user-data="item"
        :is-extended="isExtended(index)"
        :is-flipped="(x % 2) !== 0"
        :is-active="false"
        :view-id="viewId"
        :show-overlay-image="!(viewId === 1 && x === 0)"
        @item-clicked="showDetails"
      />
    </div>
  </div>
</template>

<script>
import BlockItem from './BlockItem';

import { store } from '../../common/store';

export default {
    name: "Blocks",
    components: {
        BlockItem
    },
    props: {
        blockData: {
          type: Array
        },
        setNumber: {
          type: Number,
          default: 0
        },
        viewId: {
          type: Number,
          default: 0
        }
    },

    data() {
      return {
        color: store.blockColor,
        itemsPerSet: store.blockItems,
        sets: store.blockSets,
        blockItems: []
      }
    },

    computed: {
      stairEvenClass() {
        return (this.setNumber % 2) == 0 ? 'normal' : 'flipped';
      },

      stairOddClass() {
        return (this.setNumber % 2) == 0 ? 'flipped' : 'normal';
      }
    },

    mounted() {
      this.loadBlocks();
    },

    methods: {
      loadBlocks() {
        this.blockItems = []
        var subSet = [];
        var dataCount = 0;
        for (var i=0;i < (this.sets * this.itemsPerSet); i++) {

          var dataForBlock = this.blockData[i] ? this.blockData[i] : null
          subSet.push({data: dataForBlock});
          if (((i + 1) % this.itemsPerSet) === 0) {
            this.blockItems.push(subSet.map(x=>x));
            subSet = [];
          }
        }
      },

      isExtended(index) {
        return (index % this.itemsPerSet) === 0;
      },

      showDetails(data) {
        this.$emit('itemClicked', data);
      }
    }
}
</script>

