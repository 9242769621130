<template>
  <div class="item-row row mx-0">
    <popper
      :visible-arrow="false"
      :options="{
        placement: 'bottom',
        modifiers: {
          preventOverflow: {
            boundariesElement: '.stair-container'
          }
        }
      }"
    >
      <div
        v-if="userData.data"
        class="pfv-hover-text py-1 px-2"
        :class="perpectiveClass"
      >
        {{ userData.data.PageTitle }}
      </div>
      <div v-else />

      <div
        slot="reference"
        class="item-col"
      >
        <div class="perspective">
          <div
            :style="{ 'background-color': setBackground() }"
            title=""
            :class="stepClass"
            @click="itemClicked"
          >
            <img
              v-if="isExtended && showOverlayImage"
              :src="fighterStyle"
              class="fighter"
            >
          </div>
        </div>
        ​
      </div>
    </popper>
  </div>
</template>

<script>
import { store } from "../../common/store";
import Popper from "vue-popperjs";

export default {
    name: "BlockItem",
    components: {
        Popper
    },

    props: {
        userData: {
            type: Object
        },
        isExtended: {
            type: Boolean,
            default: false
        },
        isActive: {
            type: Boolean,
            default: false
        },
        isFlipped: {
            type: Boolean,
            default: true
        },
        viewId: {
            type: Number,
            default: 0
        },
        showOverlayImage: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            color: store.blockColor
        };
    },

    computed: {
        stepClass() {
            return {
                "cube-extended": this.isExtended,
                cube: !this.isExtended,
                pointer: this.userData.data
            };
        },

        perpectiveClass() {
            if (this.viewId % 2 === 0) return this.isFlipped ? "" : "flip";
            return this.isFlipped ? "flip" : "";
        },

        fighterStyle() {
            var baseFighterUrl =
                "http://cdn.gofundraise.com.au/au/events/EID10371";
            return this.isFlipped
                ? `${baseFighterUrl}/firefighter1.png`
                : `${baseFighterUrl}/firefighter2.png`;
        }
    },

    methods: {
        itemClicked() {
            if (this.userData.data)
                this.$emit("item-clicked", this.userData.data);
        },
        setBackground() {
            const amntamnt = 0;
            if (this.userData.data) {
                return this.color;
            } else {
                return "#fff";
            }
        }
    }
};
</script>

<style>
.pointer {
    cursor: pointer !important;
}

.pfv-hover-text {
    color: white;
    vertical-align: middle;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 5px;
    z-index: 999;
}

.flip {
    -webkit-transform: scaleX(-1) !important;
    transform: scaleX(-1) !important;
}

.fighter {
    transform: scale(4.5) rotate(-40deg) !important;
    position: absolute;
    top: -16px;
    right: 21px;
    /*height: 48px;*/
    width: 27px;
    z-index: -1 !important;
}
</style>
