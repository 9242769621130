<template lang="html">
  <div>
    <b-modal
      id="modal-share-lightbox"
      centered     
      hide-header      
      hide-footer
      size="md"
      title=""
      @hide="hideSharePopup"
    >
      <div class="modal-content pfv-modal-content pfv-modal-share">
        <div class="row">
          <div class="col-12 pull-right">
            <div
              class="pfv-close-button"
              @click="hideSharePopup"
            >
              X
            </div>
          </div>
        </div>
     

        <div
          v-if="showShareLightbox"
          class="modal-body"
        >
          <div class="container  rounded">
            <social-sharing
              :url="socialSharingSetup.url"
              :title="socialSharingSetup.title"
              :description="socialSharingSetup.description"
              :quote="socialSharingSetup.quote"
              :hashtags="socialSharingSetup.hashtags"
              :twitter-user="socialSharingSetup.twitterUser"
              inline-template
            >
              <div>
                <network
                  network="facebook"
                  class="row p-3 border_bottom pointer"
                >                            
                  <i
                    class="fab fa-facebook-f social-icon rounded-circle text-white f-color"
                    style="padding: 5px 9px;"
                  />
                  <span class="ml-3 pfv-share-text">Share via Facebook</span>
                </network>
      
                <network
                  network="linkedin"
                  class="row p-3 border_bottom pointer"
                >
                  <i
                    class="fab fa-linkedin-in social-icon rounded-circle text-white l-color"
                  />
                  <span class="ml-3 pfv-share-text">Share via Linkedin</span>
                </network>

                <network
                  network="twitter"
                  class="row p-3 border_bottom pointer"
                >
                  <i class="fab fa-twitter social-icon rounded-circle text-white t-color" />
                  <span class="ml-3 pfv-share-text">Share via Twitter</span>
                </network>

                <network
                  network="email"
                  class="row p-3 border_bottom pointer"
                >
                  <i class="fa fa-envelope social-icon rounded-circle text-white e-color" />
                  <span class="ml-3 pfv-share-text">Share via Email</span>
                </network>
              </div>
            </social-sharing> 
          
            <div class="row p-3 pointer">
              <span @click="copyUrlToClipboard()">
                <input
                  id="shareUrl"
                  type="hidden"
                >
                <i class="fa fa-link social-icon rounded-circle text-white c-color" />
                <span class="ml-3 pfv-share-text">Copy Link</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import SocialSharing from "vue-social-sharing";

export default {
  name: "LightboxShareItem",
  components: {
    SocialSharing
  },

  props: {
    showShareLightbox: {
      type: Boolean,
      default: true
    },

    socialSharingSetup: {
      type: Object,
      default: null
    },

    donateLink: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      shareUrl: ""
    };
  },

  mounted() {
    this.shareUrl = this.socialSharingSetup.url;
    this.showSharePopup();
  },

  methods: {
    showSharePopup() {
      this.$bvModal.show("modal-share-lightbox");
    },

    hideSharePopup() {
      this.$bvModal.hide("modal-share-lightbox");
      this.$emit("closed", true);
    },

    copyUrlToClipboard() {
      let urlToCopy = document.querySelector("#shareUrl");
      urlToCopy.setAttribute("type", "text");
      urlToCopy.value = this.shareUrl;
      urlToCopy.select();

      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        alert("Copied url " + this.shareUrl);
      } catch (err) {
        alert("Something went wrong. Unable to copy");
      }

      urlToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
      this.hideSharePopup();
    }
  }
};
</script>

<style lang="css">
.social-icon {
  width: 26px;
  height: 26px;
}

.pointer {
  cursor: pointer;
}
.border_bottom {
  border-bottom: 1px solid #ccc;
}
.border_top {
  border-top: 1px solid #ccc;
}
.f-color {
  background-color: #45619d !important;
  padding: 5px 7px;
}
.l-color {
  background-color: #3197d1 !important;
  padding: 5px 0px 0px 6px;
}
.e-color {
  background-color: #47b9e8 !important;
  padding: 5px;
}
.t-color {
  background-color: #36a1f2 !important;
  padding: 5px;
}
.c-color {
  background-color: gray !important;
  padding: 5px;
}
.pfv-share-text:hover {
  color: #c72307;
}
</style>
