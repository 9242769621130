<template lang="html">
  <div>
    <b-modal
      id="modal-search-lightbox"
      centered
      hide-header
      hide-footer
      size="xl"
      title=""
      @hide="hideSearchPopup"
    >
      <div class="modal-content pfv-modal-content pfv-modal-search">
        <!-- start top header row -->
        <div class="row">
          <div class="col-12 pull-right">
            <div
              class="pfv-close-button"
              @click="hideSearchPopup"
            >
              X
            </div>
          </div>
        </div>


        <div
          class="modal-body"
        >
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="input-group mb-5">
                  <input
                    v-model="searchString"
                    v-debounce="searchForItems"
                    type="text"
                    class="form-control"
                    placeholder="Search"
                    aria-label="Search"
                    aria-describedby="basic-addon2"
                  >
                  <div
                    class="pfv-search-button"
                    @click="searchForItems()"
                  >
                    <div class="input-group-append">
                      <button class="pfv-btn pfv-btn-danger ml-3">
                        SEARCH
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="pageDetails && searchString !== ''"
              class="row"
            >
              <div
                v-for="(page, index) of pageDetails.Pages"
                :id="index"
                :key="index"
                class="col-sm-12 col-md-6"
              >
                <div
                  class="container"
                  style="margin:0em 1em 1em 0em; padding: 1em; border: 1px solid red;border-radius: 10px;"
                >
                  <div class="row">
                    <div class="col-9">
                      <div>
                        <h3>{{ page.PageTitle }}</h3>
                        <h5>donated by {{ page.CreatorName }}</h5>
                      </div>
                    </div>

                    <div class="col-3">
                      <div
                        class="pfv-action-link"
                        @click="onSearchedItemClick(page)"
                      >
                        View
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import apiService from '../components/inc/apiService'

export default {
  name: "LightboxSearch",

  props: {
    /**
     * The title property. Set it to what you want to display
     */
    item: {
      type: Object,
      default: null
    },
    showSearchbox: {
      type: Boolean,
      default: false
    },

    itemImages: {
      type: Array,
      default: () => [
        {
          imageUrl:
            "https://cdn.gofundraise.com.au/Upload/176182/images/poppy1.png",
          hoverImageUrl:
            "https://cdn.gofundraise.com.au/Upload/176182/images/poppy1_hover.png"
        }
      ]
    },

    additionalQueryParams: {
        type: Object,
        default: () => null
    }
  },

  data() {
    return {
      searchString: "",
      pageDetails: null
    };
  },

  watch: {
    showSearchbox(val) {
      if (val == true)
        this.showSearchPopup();
    }
  },

  methods: {
    showSearchPopup() {
      this.$bvModal.show("modal-search-lightbox");
    },

    hideSearchPopup() {
      this.$bvModal.hide("modal-search-lightbox");
      this.$emit("close");
    },

    searchForItems() {
      this.searchPages(this.searchString);
    },

    onSearchedItemClick(page) {
      this.$emit("close");
      if(this.itemImages.length > 0)
        page.baseImage = this.itemImages[0].imageUrl;
      else
        page.baseImage = page.ImagePath;
        
      this.$emit("on-click-item", page);
      this.$bvModal.hide("modal-search-lightbox");
    },

    async searchPages(searchString) {
      var vm = this;
      if (!this.searchString || this.searchString === "" || this.searchString.length < 3) {
        this.pageDetails = null;
        return;
      }
      var currentPageIndex = 1;
      var pageSize = 8;

      // TODO: Use page size parameter. Adjust the page size based on width and height of the screen
      // TODO: Rerender the Poppies on page resize
      // TODO: Move the axios calls into a Vue service and stub out the service. See:
      // https://bitbucket.org/gofundraise/gf-pagescreate/src/master/src/components/inc/BeneficiaryServicePlugin.vue

      await apiService
        .searchByText(pageSize, searchString, this.additionalQueryParams)
        .then(function(pageDetails) {
          if (pageDetails !== null) {
            vm.$data.pageDetails = pageDetails;
          }
        });
    }
  }
};
</script>

<style lang="css">
/* The Modal (background) */
.pfv-item-modal {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: table;
  transition: opacity 0.3s ease;
}

.pfv-item-modal .pfv-hero {
  margin-top: 0;
  padding-top: 1em;
  font-size: 1.7em;
  color: black;
  font-weight: 600;
}

.pfv-item-modal .pfv-body {
  margin: 20px 0;
}

.pfv-item-modal .pfv-page-title {
  color: black;
  font-weight: 700;
  font-size: 2.3em;
  margin-bottom: 0.7em;
  margin-top: 1em;
}

.pfv-item-modal .pfv-actions {
  display: table;
  vertical-align: left;
  margin-top: 2em;
  width: 50%;
}

.pfv-item-modal .pfv-actions > .pfv-donate {
  display: table-cell;
  vertical-align: left;
}

.pfv-item-modal .pfv-actions > .pfv-share {
  display: table-cell;
  vertical-align: left;
}

.pfv-label-donated-by {
  padding-top: 2em;
  display: table-cell;
  vertical-align: left;
  font-weight: 600;
  font-size: 1em;
}

.pfv-item-modal .fb-like {
  margin-bottom: 2em;
}

.pfv-search-input {
  border-radius: 10px !important;
}
.pfv-search-button {
  cursor: pointer;
}

.pfv-action-link {
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
  color: red;
}
</style>
