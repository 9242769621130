import axios from "axios"
import gfApiService from '@gf/gf-api-services'
import pagesStructure from  '../../../src/common/pagesStructure'
import { mutations, store } from '../../common/store'

export default {
  async searchPaginatedItems(pageSize, pageIndex, additionalParams) {
    let params_query = {
      params: {
        pagesize: pageSize,
        pageindex: pageIndex,
        eventcampaignid: store.eventCampaignId,
        excludepageswithnofundraising: store.excludeDisabledFundraisingPages
      }
    };

    if (additionalParams)
      Object.assign(params_query.params, additionalParams);

    var endPoint = store.apiDomain + "/v1/pages/search";
    return await axios.get(endPoint, params_query).then(function(res) {
      return res.data;
    });
  },

  async searchItem(itemId, modeType) {
    switch (modeType) {
      case 'EVENT_PAYMENT':
      case 'FRP_PAYMENT':
        var searchedResult = await this.getPaymentDigestByIds(itemId)
        searchedResult.Pages = searchedResult.DigestDetail.map(digest => {
          var data = Object.assign(pagesStructure, {
              Id: digest.Id,
              CreatorName: digest.Creator.DisplayName,
              PageTitle: digest.Title,
              Message: digest.AdditionalProperties.Message,
              Total: digest.AdditionalProperties.Amount
          })
          return JSON.parse(JSON.stringify(data))
        })
        return searchedResult            
      default:
        let params_query = {
          params: {
            pageid: itemId,
            eventcampaignid: store.eventCampaignId
          }
        };

        var endPoint = store.apiDomain + "/v1/pages/search";
        return await axios.get(endPoint, params_query).then(function(res) {
          return res.data;
        })
    } 
    
  },

  async searchByText(pageSize, searchString, additionalParams) {
    let params_query = {
      params: {
        query: searchString,
        pagesize: pageSize,
        eventcampaignid: store.eventCampaignId
      }
    };
    if (additionalParams)
      Object.assign(params_query.params, additionalParams);

    var endPoint = store.apiDomain + "/v1/pages/search";
    return await axios.get(endPoint, params_query).then(function(res) {
      return res.data;
    });
  },

  async getEventPaymentDigest(params) {
    var informationDigestService = new gfApiService.InformationDigestService(store.apiDomain.replace(/(http:\/\/|https:\/\/)/g, ''))
    var eventCampaignIds = typeof params.eventcampaignids == 'object' || typeof params.eventcampaignids == 'array'
      ? params.eventcampaignids.join(',')
      : params.eventcampaignids
    var res = await informationDigestService.getEventPaymentDigest(
      eventCampaignIds, 
      params.pagesize,
      params.pageindex,
      params.includeSubEvents,
      params.sortorder,
      params.sortby
    )

    return res
  },

  async getPaymentDigestByIds(Id) {
    var informationDigestService = new gfApiService.InformationDigestService(store.apiDomain.replace(/(http:\/\/|https:\/\/)/g, ''))
    var res = await informationDigestService.retrieveDigestDetailsByIds([{Id: Id, DigestType: 'PaymentItem'}], 2)
    return res
  },

  async getPagePaymentDigest(params) {
    var informationDigestService = new gfApiService.InformationDigestService(store.apiDomain.replace(/(http:\/\/|https:\/\/)/g, ''))
    var fundraisingpageids = typeof params.fundraisingpageids == 'object' || typeof params.fundraisingpageids == 'array'
      ? params.fundraisingpageids.join(',')
      : params.fundraisingpageids
    var res = await informationDigestService.getPagePaymentDigest(
      fundraisingpageids, 
      params.pagesize,
      params.pageindex,
      params.includeSubEvents,
      params.sortorder,
      params.sortby
    )

    return res
  }

}
