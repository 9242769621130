
import pagesStructure from '../../../common/pagesStructure'
import apiService from '../../inc/apiService'

export default {
  distributionTypes: {
      PaymentTotalDivider: "PAYMENT_TOTAL_DIVIDER"
  },

  load: async function (vm) {
      var params = vm.mode.data;
      vm.itemClickable = true;
      vm.showItemHoverText = false;
      vm.showFieldSearch = false;
      params.pagesize = vm.fieldRows * vm.fieldColumns;
      params.pageindex = vm.currentPageIndex

      var result = await apiService.getEventPaymentDigest(params);
      
      result.Pages = result.PaymentDigests.map(digest => {
        var data = Object.assign(pagesStructure, {
            Id: digest.Id,
            CreatorName: digest.Name,
            PageTitle: digest.Name,
            Message: digest.Message,
            Total: digest.HierarchyTotal
        })
        return JSON.parse(JSON.stringify(data))
      })

      return result;
  }
}
