<template lang="html">
  <div
    id="lightbox-item"
  >
    <lightbox-share-item
      v-if="showShareLightbox"
      :social-sharing-setup="socialSharingSetup"
      :donate-link="donateLink"
      @closed="showShareLightbox = false"
    />

    <b-modal
      id="modal-item-lightbox"
      centered
      hide-header
      hide-footer
      size="xl"
      title="Show the details"
    >
      <div class="modal-content pfv-modal-content pfv-modal-item">
        <div class="row">
          <div class="col-12 pull-right">
            <div
              class="pfv-close-button"
              @click="hideItemPopup"
            >
              X
            </div>
          </div>
        </div>

        <div
          class="modal-body"
        >
          <div class="row">
            <div class="text-center d-none d-md-block col-md-4">
              <img
                :src="encodeURI(item.baseImage)"
                class="col"
              >
            </div>
            <div class="col-md-8 col-sm-12">
              <div class="pfv-hero">
                {{ headerHero }}
              </div>

              <div class="pfv-page-title">
                {{ item.PageTitle }}
              </div>
              <div class="pfv-message">
                <span v-html="item.Message" />
              </div>
              <div>
                <b-button
                  class="btn btn-danger"
                  @click="openDonateLink()"
                >
                  <i class="fas fa-heart" /> {{ donateButtonLabel }}
                </b-button>&nbsp;&nbsp;
                <b-button
                  class="btn btn-info"
                  @click="showShareLightbox = true"
                >
                  <i class="fas fa-share" /> SHARE
                </b-button>
              </div>&nbsp;&nbsp;
              <h3>{{ donorLabel }}</h3>
              <h5> {{ item.CreatorName }}</h5><br>
              <div v-if="showCharityName">
                <h3>{{ charityLabel }}</h3>
                <h5> {{ item.Beneficiary.BeneficiaryName }}</h5>
              </div>
              <br>
              <div class="row">
                <div class="col-12">
                  <iframe
                    :src="facebookLink"
                    width="450"
                    height="80"
                    style="border:none;overflow:hidden"
                    scrolling="no"
                    frameborder="0"
                    allowTransparency="true"
                    allow="encrypted-media"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import LightboxShareItem from "./lightboxShareItem.vue";
/**
 * This is the LightboxItem component.
 *   @on-click-share-item="onShareItemClick"
 */
export default {
  name: "LightboxItem",
  components: {
    LightboxShareItem
  },

  props: {
    /**
     * The title property. Set it to what you want to display
     */
    item: {
      type: Object,
      default: null
    },

    showLightbox: {
      type: Boolean,
      default: true
    },

    headerHero: {
      type: String,
      default: "IN LOVING MEMORY"
    },

    socialSharingSetup: {
      type: Object,
      default: null
    },

    donateLink: {
      type: String,
      default: ""
    },

    facebookLink: {
      type: String,
      default: ""
    },

    donorLabel: {
      type: String,
      default: ""
    },

    showCharityName: {
      type: Boolean,
      default: false
    },

    charityLabel: {
      type: String,
      default: ""
    },

     donateButtonLabel: {
      type: String,
      default: ""
    },
  },

  data() {
    return {
      shareUrl: "",
      showShareLightbox: false
    };
  },

  watch: {
      showLightbox(val) {
          if (val === true)
            this.showItemPopup();
      }
  },

  mounted() {
    var self = this;
    this.shareUrl = this.socialSharingSetup.url;
    this.showItemPopup();
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('closed');
    })
  },

  methods: {
    showItemPopup() {
      this.showShareLightbox = false;
      this.$bvModal.show("modal-item-lightbox");
    },

    hideItemPopup() {
      this.$bvModal.hide("modal-item-lightbox");
      this.$emit('closed');
    },

    openDonateLink() {
      var linkToOpen =
        this.donateLink +
        "?CreatorName=" +
        encodeURIComponent(this.item.CreatorName);
      window.open(this.donateLink);
    }
  }
};
</script>

<style lang="css">
.pfv-modal-content {
  border: none !important;
}
.pfv-item-modal {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  transition: opacity 0.3s ease;
}
.pfv-hero {
  margin-top: 0;
  padding-top: 1em;
  font-size: 1.7em;
  color: black;
  font-weight: 600;
}

.pfv-item-modal .pfv-body {
  margin: 20px 0;
}

.pfv-page-title {
  color: black;
  font-weight: 700;
  font-size: 2.3em;
  margin-bottom: 0.7em;
  margin-top: 1em;
}

.pfv-message {
  max-height: 12em;
  margin-bottom: 2em;
  overflow: hidden;
}

.pfv-item-modal .pfv-actions {
  display: table;
  vertical-align: left;
  margin-top: 2em;
  width: 50%;
}

.pfv-item-modal .pfv-actions > .pfv-donate {
  display: table-cell;
  vertical-align: left;
}

.pfv-item-modal .pfv-actions > .pfv-share {
  display: table-cell;
  vertical-align: left;
}

.pfv-label-donated-by {
  padding-top: 2em;
  display: table-cell;
  vertical-align: left;
  font-weight: 600;
  font-size: 1em;
}

.pfv-item-modal .fb-like {
  margin-bottom: 2em;
}

.pfv-pointer {
  cursor: pointer;
}

.pfv-text-hover-underline:hover {
  text-decoration: underline;
}
</style>
