import GfFieldView from '../components/FieldView/GfFieldView.vue'
import BlockView from '../components/Block/BlockView.vue';

export default {
    "STD": {
        baseComponent: GfFieldView,
        loader: "eventFrpLoader"
    },

    "FRP_TOTAL": {
        baseComponent: GfFieldView,
        loader: "paymentFrpTotalLoader"
    },

    "BLOCK": {
        baseComponent: BlockView,
        loader: ""
    },

    "EVENT_PAYMENT": {
        baseComponent: GfFieldView,
        loader: "eventPaymentFrpLoader"
    },

    "FRP_PAYMENT": {
      baseComponent: GfFieldView,
      loader: "paymentFrpLoader"
  }
}

