import Vue from "vue";

export const store = Vue.observable({
    blockColor: '',
    blockItems: 4,
    blockSets: 3,
    baseDomain: '',
    apiDomain: '',
    eventCampaignId: ''
});

export const mutations = {
    setBlockColor(blockColor) {
        store.blockColor = blockColor;
    },

    setBlockItems(blockItems) {
        store.blockItems = blockItems;
    },

    setBlockSets(sets) {
        store.blockSets = sets;
    },

    setBaseDomain(baseDomain) {
        store.baseDomain = baseDomain;
    },

    setApiDomain(apiDomain) {
      store.apiDomain = apiDomain
    },

    setEventCampaignId(eventCampaignId) {
      store.eventCampaignId = eventCampaignId
    }
};