<template lang="html">
  <div>
    <popper
      :visible-arrow="false"
      :options="{
        placement: 'top',
        modifiers: {
          preventOverflow: {
            boundariesElement: container, 
          },
          offset: { offset: '0,10px' } 
        }
      }"
    >
      <div
        v-if="showItemHoverText"
        class="pfv-hover-text py-1 px-2"
      >
        {{ item.PageTitle }}
      </div>
      <div
        slot="reference"
        :v-if="theCanvasDiv"
        :class="itemCSSClass"    
        :style="{zIndex: item.position.zindex, transform: perspectiveScale, top: perspectiveTop, left:perspectiveLeft }"
        style="position: absolute"
        @mouseover="hovering = true"
        @mouseleave="hovering = false"
        @click="onItemClick"
      >   
        <img          
          ref="image"
          :src="encodeURI(imagePath)"
          :style="{zIndex: item.position.zindex}"
        >
      </div>
    </popper>
  </div>
</template>

<script>
import Popper from "vue-popperjs";

export default {
  components: {
    Popper
  },

  props: {
    id: {
      type: Number,
      default: 0
    },
    item: {
      type: Object,
      default: null
    },

    showItemHoverText: {
      type: Boolean,
      default: true
    },

    theCanvasDiv: null,

    itemTopOffsetScaling: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      hovering: false
    };
  },

  computed: {
    itemCSSClass() {
      return "the-item-style the-item-debug";
    },

    perspectiveScale() {
      const imageScale =
        "Scale(" + (101 - this.item.position.distance) / 100 + ")";
      return imageScale;
    },

    imagePath() {
      if (this.$data.hovering == false) {
        return this.item.baseImage;
      } else {
        return this.item.hoverImage;
      }
    },

    perspectiveTop() {
      return (
        Math.floor(this.theCanvasDiv.height * this.itemTopOffsetScaling) +
        this.perspectivetop() +
        "px"
      );
    },

    perspectiveLeft() {
      return this.perspectiveleft() + "px";
    }
  },

  methods: {
    onItemClick() {
      this.$emit("on-click-item", this.item);
    },

    perspectivetop() {
      return Math.floor(
        this.theCanvasDiv.height -
          (this.theCanvasDiv.height / 100) * this.item.position.distance * 1.4
      );
    },

    perspectiveleft() {
      return Math.floor(
        (this.theCanvasDiv.width / 100) * this.item.position.horizion
      );
    }
  }
};
</script>

<style lang="css">
.the-item-style {
  position: absolute;
  background-repeat: round;
  display: block;
  overflow: hidden;
}

.the-item-style :hover {
  cursor: pointer;
}

.pfv-hover-text {
  color: white;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.7); /* Black w/ opacity */
  border-radius: 5px;
  z-index: 999;
}
</style>

