export default {
  Id: "",
  Url: "",
  FundraisingEnabled: true,
  DisplayDonations: true,
  DisplayDonationAmount: true,
  DonationUrl: "",
  CreatorName: "",
  Beneficiary: {},
  Event: {},
  defaults: {},
  ActivityData: {
      TotalStepInNumber: "",
      TotalDistanceInMeters: "",
      TotalTimeInMinutes: "",
      TotalEnergyInCalories: ""
  },
  PageTags: [],
  Meta: [],
  PageTitle: "",
  ImagePath: "",
  Message: "",
  PageType: "S",
  RaiseTarget: "",
  Total: ""
}
