
import apiService from '../apiService'

export default {
    distributionTypes: {
        PaymentTotalDivider: "PAYMENT_TOTAL_DIVIDER"
    },

    load: async function (vm) {
        var params = vm.mode.data;
        vm.itemClickable = false;
        vm.showItemHoverText = false;
        vm.showFieldSearch = false;
        
        var result = await apiService.searchItem(params.frpId);

        if (params.distributionType && params.distributionType == this.distributionTypes.PaymentTotalDivider) {
            return this.generateItemsBasedOnTotal(result, params.divisionValue, params.paymentTotal)
        }
    },

    generateItemsBasedOnTotal: function (item, divisionValue, paymentTotal) {
        if (!item || item.Pages.length == 0)
            return item;
        var total = paymentTotal? paymentTotal : parseInt(item.Pages[0].Total);
        var numberOfClones = Math.floor( total/ divisionValue) - 1;

        for (var cloneCount = 0; cloneCount < numberOfClones; cloneCount++) {
            var clonedItem = Object.assign({}, item.Pages[0]);
            clonedItem.Id = `${clonedItem.Id}_${cloneCount + 1}`;
            item.Pages.push(clonedItem);
        }

        return item;
    }
}
