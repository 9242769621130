<template>
  <div
    id="field-canvas-wrapper"
    class="field-canvas-wrapper"
  >
    <div
      id="field-canvas"
      ref="field-canvas"
      :class="cssStyle"     
    />    
    <slot />
  </div>
</template>

<script>
export default {

 props: {
   cssStyle : {
      type: String,
      default: "field-canvas"
   },   
 },
  render () {
     return true;
   }
 
}
</script>

<style>
.field-canvas-wrapper {
  
  max-height: 500px;
  height: 100vh;
  /* border: 3px solid red; */
  overflow: hidden;
  display: inline-block;
  position: relative;
  width: 100%;
    
    
    
}

.field-canvas {
   max-height: 500px;
  height: 100vh;
  width: 100%;
  
/*  border: 3px solid green; */

  
  
  overflow: hidden;

  display: inline-block;
  position: relative;
    
}

</style>