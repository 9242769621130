<script>
export default {
  name: "FisherYates",
  props: {
    maxPoppiImages: {
      type: Number,
      default: 8
    },

    rows: {
      type: Number,
      default: 4
    },
    columns: {
      type: Number,
      default: 12
    }
  },

  data() {
    return {
      itemPositions: null
    };
  },

  computed: {
    itemWidth() {
      return Math.floor(100 / this.columns); /* in % of view widht */
    },
    itemHeight() {
      return Math.floor(60 / this.rows); /* in % of view height */
    }
  },

  created() {
    this.shuffleSlots();
  },

  methods: {
    shuffleSlots() {
      const arraySize = this.rows * this.columns;
      this.$data.itemPositions = new Array(arraySize);

      for (var i = 0; i < this.rows; i++) {
        for (var j = 0; j < this.columns; j++) {
          const index = i * this.columns + j;
          const position = Object.create(null);
          /* 
                     %    Location
                     -5 =  Left Corner 
                     40 =  Middle
                     85 =  Right Corner
                    */

          position.horizion =
            this.itemWidth * j -
            this.getItemPosition(0, this.itemWidth / 2) -
            this.itemWidth / 4;
          if (position.horizion < 0) {
            position.horizion = 0;
          }
          /* 
                     %    Location
                     90 = far away 
                     70 = middle
                     60 = font middle - full tree is visible
                     35 = front line - just the flower part is visible
                    */
          position.distance =
            25 +
            this.itemHeight * (i + 1) +
            this.getItemPosition(0, this.itemHeight / 6);
          position.zindex = this.columns - i;
          this.$data.itemPositions[index] = position;
        }
      }
    },

    getTopSpace() {
      // To put the search - found item
      // first row , middle column
      return Math.floor(this.columns / 2);
    },

    getItemStyle() {
      return Math.floor(Math.random() * (this.maxPoppiImages - 1) + 1);
    },

    getItemPosition(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },

    getNextPosition() {
      let randomIndex = Math.floor(
        Math.random() * this.$data.itemPositions.length
      );
      let deletedOne = this.$data.itemPositions.splice(randomIndex, 1);
      return deletedOne[0];
    }
  },
  render() {
    return true;
  }
};
</script>


